import Guide from '@scripts/guide'
import '@styles/home.scss'

import Router from '@scripts/utils/Router.js'
import common from '@scripts/routes/common'

const routes = new Router({
  common
})

const { DEV } = import.meta.env

document.addEventListener('DOMContentLoaded', () => {
  window.$ = jQuery
  routes.loadEvents()
})
